<template>
  <div>
    <v-card
      flat
    >
      <v-card-text>
        <v-row>
          <v-col
            md="4"
            cols="12"
          ></v-col>
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="deposit_slips.date_of_deposit"
                label="Date Deposited"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="deposit_slips.time_of_deposit"
                label="Time Deposited"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="deposit_slips.bank_depositories.bank_code"
                label="Bank Code"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="deposit_slips.bank_depositories.account_no"
                label="Bank Account #"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="amount_formated"
                label="Amount"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="balance_formated"
                label="Balance"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table v-if="deposit_slips.is_payment"
                  dense
                  :headers="headers"
                  :items="encoded_data"
                  disable-pagination
                  hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Data</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn color="success" @click="add_payment" v-if="deposit_slips.is_payment"> Add Loan
            Payment
          </v-btn>
          <v-btn color="success" @click="add_payment_deductions" v-else> Add Deduction Payment
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ item.due_date }}
          </td>
          <td>
            {{ item.loan_data.name }}
          </td>
          <td>
            {{ item.or_date }}
          </td>
          <td>
            {{ item.or_no }}
          </td>
          <td>
            {{ formatPrice(item.amount) }}
          </td>
          <td>
            {{ formatPrice(item.interest) }}
          </td>
          <td>
            {{ formatPrice(item.penalty) }}
          </td>
          <td>
            {{ formatPrice(item.ca) }}
          </td>
          <td>
            {{
            formatPrice((parseFloat(item.amount)+parseFloat(item.interest)+parseFloat(item.penalty)-parseFloat(item.ca)))
            }}
          </td>
          <td class="text-center">
            <v-icon
              v-if="!is_deleting"
              class="mr-2"
              color="error"
              @click="delete_payment(item)"
            >
              {{icons.mdiDelete}}
            </v-icon>
            <v-progress-circular color="info" indeterminate
                                 v-else></v-progress-circular>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-data-table v-else dense
                  :headers="headers2"
                  :items="encoded_data"
                  disable-pagination
                  hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Data</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn color="success" @click="add_payment" v-if="deposit_slips.is_payment"> Add Loan
            Payment
          </v-btn>
          <v-btn color="success" @click="add_payment_deductions" v-else> Add Deduction Payment
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ item.particulars }}
          </td>
          <td>
            {{ item.loan_data.name }}
          </td>
          <td>
            {{ formatPrice(item.amount) }}
          </td>
          <td class="text-center">
            <v-icon
              v-if="!is_deleting"
              class="mr-2"
              color="error"
              @click="delete_payment(item)"
            >
              {{icons.mdiDelete}}
            </v-icon>
            <v-progress-circular color="info" indeterminate
                                 v-else></v-progress-circular>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="is_add_payment" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title>
            <h4 class="font-weight-light">ADD LOAN PAYMENT</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table class="mt-4" dense
                        :headers="headers_payment"
                        :items="not_encoded_data"
                        disable-pagination
                        hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Loans Not Yet Deposited Data</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-autocomplete
                  dense
                  outlined
                  v-model="search_data"
                  :items="search_items"
                  :loading="isLoading"
                  @keyup.enter="searching($event.target.value)"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="id"
                  label="Search"
                  placeholder="Press Enter to Search "
                  :prepend-icon="icons.mdiAccountSearchOutline"
                  @change="get_search_items_info"
                ></v-autocomplete>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.due_date }}
                </td>
                <td>
                  {{ item.or_date }}
                </td>
                <td>
                  {{ item.or_no }}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  {{ formatPrice(item.interest) }}
                </td>
                <td>
                  {{ formatPrice(item.penalty) }}
                </td>
                <td class="text-center">
                  <v-icon
                    v-show="item.deposit_slip_id===null && not_encoded_data.map(function (x) {
            return x.id;
          }).indexOf(item.id)===0 && can_remove_penalty(item) && item.penalty>0"
                    v-if="!is_saving"
                    class="mr-2"
                    color="error"
                    @click="remove_penalty(item)"
                  >
                    {{icons.mdiDelete}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-if="is_saving"></v-progress-circular>
                </td>
                <td>
                  {{
                  formatPrice(parseFloat(item.amount)+parseFloat(item.interest)+parseFloat(item.penalty))
                  }}
                </td>
                <td class="text-center">
                  <v-icon
                    v-show="item.deposit_slip_id===null && not_encoded_data.map(function (x) {
            return x.id;
          }).indexOf(item.id)===0"
                    v-if="!is_saving"
                    class="mr-2"
                    color="success"
                    @click="load_to_deposit(item)"
                  >
                    {{icons.mdiCheck}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-if="is_saving"></v-progress-circular>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-btn color="error" class="w-full" @click="is_add_payment = false"> Close</v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="is_add_payment_deductions" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title>
            <h4 class="font-weight-light">ADD DEDUCTIONS PAYMENT</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table class="mt-4" dense
                        :headers="headers_deduction_payment"
                        :items="not_encoded_data"
                        disable-pagination
                        hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Loans Not Yet Deposited Data</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-autocomplete
                  dense
                  outlined
                  v-model="search_data"
                  :items="search_items"
                  :loading="isLoading"
                  @keyup.enter="searching($event.target.value)"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="id"
                  label="Search"
                  placeholder="Press Enter to Search "
                  :prepend-icon="icons.mdiAccountSearchOutline"
                  @change="get_search_items_info"
                ></v-autocomplete>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.particulars }}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td class="text-center">
                  <v-icon
                    v-if="!is_saving"
                    class="mr-2"
                    color="success"
                    @click="load_to_deposit(item)"
                  >
                    {{icons.mdiCheck}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-if="is_saving"></v-progress-circular>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-btn color="error" class="w-full" @click="is_add_payment_deductions = false"> Close
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="is_saving" persistent max-width="60%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title
          ><h4 class="font-weight-light">PAYMENT DETAILS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" class="multi-col-validation mt-6">
            <v-checkbox v-if="is_damayan" label="is have CA?" v-model="is_have_ca" hide-details
                        class="me-3 mt-1"></v-checkbox>
            <v-row class="mt-4">
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="or_date"
                  label="OR Date"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="date"
                ></v-text-field>
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="or_no"
                  label="OR #"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col
                md="12"
                cols="12"
              >
                <v-text-field
                  v-model="amount"
                  label="Amount"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-btn color="success" class="w-full mb-2" @click="save_payment"> Save Payment</v-btn>
        <v-btn color="error" class="w-full" @click="is_saving = false"> Close</v-btn>
      </v-card>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiCheck, mdiDelete} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {

      is_have_ca: false,
      is_saving: false,
      is_damayan: false,
      isLoading: false,
      is_add_payment: false,
      is_add_payment_deductions: false,
      is_deleting: false,

      search_data: '',
      search_items: [],
      payment_id: '',
      or_date: '',
      or_no: '',
      amount: '',
      not_encoded_data: [],
      search: '',
      headers: [
        {text: 'Due Date', value: 'due_date', sortable: true},
        {text: 'Name', value: 'loan_data.name', sortable: true},
        {text: 'OR Date', value: 'or_date', sortable: true},
        {text: 'OR #', value: 'or_no', sortable: true},
        {text: 'Amount', value: 'amount', sortable: true},
        {text: 'Interest', value: 'interest', sortable: true},
        {text: 'Penalty', value: 'penalty', sortable: true},
        {text: 'CA', value: 'penalty', sortable: true},
        {text: 'Total', value: 'amount', sortable: false},
        {text: 'Action', value: 'amount', sortable: false},
      ],
      headers2: [
        {text: 'Particulars', value: 'due_date', sortable: true},
        {text: 'Name', value: 'loan_data.name', sortable: true},
        {text: 'Amount', value: 'loan_data.name', sortable: true},
        {text: 'Action', value: 'amount', sortable: false},
      ],
      headers_deduction_payment: [
        {text: 'Particulars', value: 'due_date', sortable: true},
        {text: 'Amount', value: 'loan_data.name', sortable: true},
        {text: 'Action', value: 'amount', sortable: false},
      ],
      headers_payment: [
        {text: 'Due Date', value: 'due_date', sortable: true},
        {text: 'OR Date', value: 'or_date', sortable: true},
        {text: 'OR #', value: 'or_no', sortable: true},
        {text: 'Amount', value: 'amount', sortable: true},
        {text: 'Interest', value: 'interest', sortable: true},
        {text: 'Penalty', value: 'penalty', sortable: true},
        {text: 'Remove Penalty', value: 'penalty', sortable: true},
        {text: 'Total', value: 'amount', sortable: false},
        {text: 'Action', value: 'amount', sortable: false},
      ],
    };
  };
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiDelete,
          mdiCheck,
        },
      }
    },
    data() {
      return initialState()
    },
    props: {
      balance_formated: String,
      amount_formated: String,
      deposit_slips: Object,
      encoded_data: Array,
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('ggc_loans', ['search_ggc_loan', 'search_ggc_loan_unpaid_deduction']),
      ...mapActions('ggc_loan_payments', ['ggc_loan_payment_remove_penalty']),
      reset() {
        Object.assign(this.$data, initialState())
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      add_payment() {
        this.search_items = []
        this.not_encoded_data = []
        this.is_add_payment = true
        this.search_data = ''
      },
      add_payment_deductions() {
        this.search_items = []
        this.not_encoded_data = []
        this.is_add_payment_deductions = true
        this.search_data = ''
      },
      can_remove_penalty(item) {
        var is_can_remove = false
        if (moment(item.due_date, 'YYYY-MM-DD').format('YYYY-MM-DD') === moment().startOf('month').format('YYYY-MM-DD')
          || moment(item.due_date, 'YYYY-MM-DD').format('YYYY-MM-DD') === moment().endOf('month').format('YYYY-MM-DD')) {
          is_can_remove = true
        } else {
          var compareDate = moment(item.due_date, 'YYYY-MM-DD')
          var startDate = moment().startOf('month')
          var endDate = moment().endOf('month')

          is_can_remove = compareDate.isBetween(startDate, endDate) //false in this case
        }
        return is_can_remove
      },
      async remove_penalty(item) {
        if (confirm('Are you sure to delete this penalty?')) {
          await this.ggc_loan_payment_remove_penalty({
            id: item.id,
          })
            .then(() => {
              this.not_encoded_data = []
              this.searching(this.search_data)
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      load_to_deposit(item) {
        this.payment_id = item.id
        if (this.is_add_payment_deductions) {
          this.is_saving = false
          this.$emit('data', {
            balance: parseFloat(this.deposit_slips.balance) - parseFloat(item.amount),
            loan_id: item.id,
            particulars: item.particulars,
            details: item.details,
            amount: item.amount,
            deposit_slip_id: this.deposit_slips.id,
            is_deduction: 1,
          })
          this.is_add_payment_deductions = false
        } else {
          this.amount = parseFloat(item.amount) + parseFloat(item.interest) + parseFloat(item.penalty)
          this.is_saving = true
        }
      },
      async searching(value) {
        this.payment_id = 0
        this.is_have_ca = false
        this.is_damayan = false
        this.isLoading = true
        if (this.is_add_payment_deductions) {
          await this.search_ggc_loan_unpaid_deduction({
            search_word: value,
          })
            .then(response => {
              this.search_items = response.data
              this.isLoading = false
            })
        } else {
          await this.search_ggc_loan({
            search_word: value,
          })
            .then(response => {
              this.search_items = response.data
              this.isLoading = false
            })
        }

      },
      get_search_items_info(value) {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(value)
          if (index != -1) {
            if (this.is_add_payment_deductions) {
              this.not_encoded_data = this.search_items[index].deductions
            } else {
              this.is_damayan = this.search_items[index].type_of_loan === 'Damayan'
              this.not_encoded_data = this.search_items[index].payment_unpaid
            }
            this.payment_id = 0
          } else {
            this.not_encoded_data = []
            this.payment_id = 0
          }
        } else {
          this.not_encoded_data = []
          this.payment_id = 0
        }
      },
      save_payment() {
        if (this.$refs.form.validate()) {
          this.$emit('data', {
            balance: parseFloat(this.deposit_slips.balance) - parseFloat(this.amount),
            id: this.payment_id,
            or_no: this.or_no,
            or_date: this.or_date,
            deposit_slip_id: this.deposit_slips.id,
            is_deduction: 0,
            is_have_ca: this.is_have_ca,
          })
          this.is_saving = false
          this.is_add_payment = false
          this.is_add_payment_deductions = false
          this.reset()
        }
      },
      delete_payment(value) {
        this.$emit('remove_data', {
          balance: this.deposit_slips.is_payment ? parseFloat(this.deposit_slips.balance) + parseFloat(value.amount) + parseFloat(value.interest) + parseFloat(value.penalty) : parseFloat(value.amount),
          id: value.id,
          or_no: this.or_no,
          or_date: this.or_date,
          deposit_slip_id: value.deposit_slip_id,
          is_deduction: this.deposit_slips.is_payment ? 0 : 1,
        })
        this.is_saving = false
        this.is_add_payment = false
        this.is_add_payment_deductions = false
        this.reset()
      },
    }
  }
</script>
